<template>
  <MobileHeaderComponent />
  <HeaderComponent />
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
        <Component :is="Component" />
    </transition>
  </router-view>
  <FooterComponent />
</template>

<script>
  import FooterComponent from './components/FooterComponent.vue';
  import HeaderComponent from './components/HeaderComponent.vue';
  import MobileHeaderComponent from './components/MobileHeaderComponent.vue';

  export default {
    components: {
      FooterComponent,
      HeaderComponent,
      MobileHeaderComponent,
    }
  }
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

* {
  font-family: "Lato", sans-serif;
}
a {
  text-decoration: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s ease-out;
}

</style>
