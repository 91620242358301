<template>
  <section class="principal-header">
    <section class="contact-info-bar">
      <p>ATENDER >> ENTENDER >> RESOLVER</p>
      <router-link class="button" to="/orcamento">SOLICITE UM ORÇAMENTO</router-link>
      <div>
        <i class="bi bi-telephone-fill"> (11) 2026-1819</i>
        <a href="https://instagram.com/soluciona.assessoria" target="_blank"><i class="bi bi-instagram"></i></a>
        <a href="https://www.linkedin.com/company/soluciona-assessoria" target="_blank"><i
            class="bi bi-linkedin"></i></a>
      </div>
    </section>
    <section class="menu">
      <img src="logo_name.png" alt="logo-nome-soluciona">
      <ul>
        <router-link class="menuItem" to="/">HOME</router-link>
        <router-link class="menuItem" to="/sobrenos">SOBRE NÓS</router-link>
        <router-link class="menuItem" to="/servicos">SERVIÇOS</router-link>
        <router-link class="menuItem" to="/clientes">CLIENTES</router-link>
        <router-link class="menuItem" to="/parceiros">PARCEIROS</router-link>
        <router-link class="menuItem" to="/ti360">TI 360</router-link>
        <router-link class="blog-button" to="/blog">BLOG</router-link>
      </ul>
    </section>
  </section>
</template>

<script>
export default {
  name: 'HeaderComponent',
}
</script>

<style scoped>
.contact-info-bar {
  display: flex;
  align-items: center;
  justify-content: space-around;

  background-color: #E7CD56;
  font-weight: 600;
}

.contact-info-bar div {
  font-size: 1.4em;
}

.contact-info-bar div a {
  color: black;
}

.contact-info-bar div i {
  margin: auto 5px;
}

.menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  box-shadow: 0 4px 4px 0 rgb(223, 216, 216);
  padding: 25px 0;
}

.menu img {
  width: 220px;
}

.menu .menuItem {
  display: inline-block;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background .2s;
  cursor: pointer;
  color: black;
}


.menu .blog-button {
  background-color: #E7CD56;
  border: 1px solid #E7CD56;
  border-radius: 5px;
  padding: 10px 15px;
  margin: auto 2px;
  transition: background .3s;
  font-weight: bold;
  color: black;
}

.menu .blog-button:hover {
  background-color: white;
}

.contact-info-bar .button {
  background-color: #E7CD56;
  border: 1px solid #222220;
  border-radius: 15px;
  color: black;
  padding: 10px 15px;
  margin: auto 2px;
  transition: all .2s;
}

.contact-info-bar .button:hover {
  background-color: white;
}

@media (max-width: 984px) {
  .principal-header {
    display: none
  }
}

</style>
